<template>
  <v-card class="pa-2" :loading="saving" :disabled="saving">
    <v-card-title style="">Add New Root</v-card-title>
    <v-card-subtitle>
      {{ group.group.name }}
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-if="roots"
        v-model="new_path_rule_issued_to"
        :items="roots"
        :loading="roots_loading"
        :search-input.sync="valid_to_search"
        hide-no-data
        item-text="subject_fqdn.friendly_name"
        item-value="id"
        label="Valid To"
        placeholder="Start typing to search"
        class="custom-placeholder"
        clearable
        return-object
      >
        <template v-slot:item="data">
          <div class="pa-1">
            <strong>{{ data.item.subject_fqdn.friendly_name }}</strong> <br />
            <span style="font-size:.8em;"
              >{{ data.item.valid_from | moment("MMM Do YYYY") }} -
              {{ data.item.valid_to | moment("MMM Do YYYY") }}</span
            >
          </div>
        </template>
      </v-autocomplete>

      <template v-if="duplicate_root">
        <v-alert type="info" text
          >Only one path can exist for each root. Please delete the existing
          rule first if you wish to make a new one.</v-alert
        >
      </template>
      <template v-else-if="new_path_rule_issued_to">
        <v-radio-group v-model="new_path_rule_using" dense hide-deta>
          <template v-slot:label>
            <strong style="font-size:1.2em;">Certificate Policies</strong>
          </template>

          <v-radio :key="2" :value="2" class="mt-2">
            <template v-slot:label>
              <div>
                No initial requirements
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" small
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <span
                    >Certificates may or may not be valid for a policy.
                    <br />
                    Policy requirements applied by CA certificates in the
                    certification paths will still be enforced.
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-radio>
          <v-radio :key="1" :value="1">
            <template v-slot:label>
              <div>
                Valid for at least one policy
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" small
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <span
                    >Certificates that are not valid for a policy will not be
                    included. <br />
                    (Require Explicit = True)
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-radio>
          <div
            v-if="new_path_rule_using === 1"
            class="ml-3 pl-4 mb-2 pb-2"
            style="border-left:1px solid #7192B0;"
          >
            <v-checkbox
              v-model="avoid_bridge_cas"
              :disabled="new_path_rule_using === 2"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                Avoid use of Bridge CAs
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" small
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <span>Inhibit Policy Mapping </span>
                </v-tooltip>
              </template>
            </v-checkbox>
          </div>
          <v-radio :key="3" :value="3" :disabled="root_policies.length == 0">
            <template v-slot:label>
              <div>
                Valid for selected policies
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" small
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <span
                    >Certificates that are not valid for one of the selected
                    policies will not be included. <br />
                    (Require Explicit = True)
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-radio>
          <div
            v-if="new_path_rule_using === 3"
            class="ml-3 pl-4 mb-2 pb-2"
            style="border-left:1px solid #7192B0;"
          >
            <v-checkbox
              v-model="avoid_bridge_cas"
              label="Inhibit Policy Mapping"
              :disabled="new_path_rule_using === 2"
              class="mt-1 mb-0"
              dense
              hide-details
            ></v-checkbox>

            <v-autocomplete
              :disabled="new_path_rule_using !== 3"
              v-model="new_path_rule_policies"
              :items="root_policies"
              :loading="root_policies_loading"
              hide-no-data
              hide-details
              item-text="search"
              label="Selected policies"
              placeholder="Select one or more policies"
              class="custom-placeholder"
              return-object
              multiple
              chips
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item, new_path_rule_policies)"
                >
                  {{ data.item.friendly_name }}
                </v-chip>
              </template>

              <template v-slot:item="data">
                <div class="pa-1" style="font-size:.85em;">
                  <strong>{{ data.item.friendly_name }}</strong> <br />
                  <span style="font-size:.8em;">{{
                    data.item.identifier
                  }}</span>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-radio-group>
      </template>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="close_dialog" color="gray">Cancel</v-btn>
      <v-btn @click="save_rule" color="success">Add</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from "../../../store/UserService";

export default {
  name: "NewPathRule",
  props: ["group"],
  data: function() {
    return {
      dialog: false,
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null,
      public_keys: [],
      roots: [],
      duplicate_root: false,
      new_path_rule_issued_to: null,
      new_path_rule_policies: null,
      roots_loading: false,
      valid_to_search: null,
      new_path_rule_using: 1,
      root_policies: [],
      root_policies_loading: false,
      avoid_bridge_cas: false
    };
  },
  methods: {
    close_dialog: function() {
      this.$emit("close-dialog");
      console.log("i did this");
      this.new_path_rule_issued_to = null;
      this.new_path_rule_policies = null;
    },
    save_rule: function() {
      let self = this;
      self.saving = true;
      UserService.postLocalApi("monitoring-group-path-rules/", {
        monitoring_group: this.group.group.id,
        valid_to_id: this.new_path_rule_issued_to.id,
        using: this.new_path_rule_using,
        policies: this.new_path_rule_policies,
        name: this.new_rule_name,
        avoid_bridge_cas: this.avoid_bridge_cas
      })
        .then(function(response) {
          self.$emit("add-path-rule", self.group, response.data);
          self.$emit("close-dialog");
          self.new_path_rule_issued_to = null;
          self.new_path_rule_policies = null;
          self.saving = false;
          self.editing = false;
        })
        .catch(function(error) {
          console.log(error);

          self.$emit("close-dialog");
          self.saving = false;
        });
    }
  },
  watch: {
    root_policies(val) {
      if (val.length === 0 && this.new_path_rule_using === 3) {
        this.new_path_rule_using = 1;
      }
    },
    new_path_rule_using(val) {
      if (val !== 3) {
        this.new_path_rule_policies = [];
      }
    },
    new_path_rule_issued_to(val) {
      let self = this;
      if (val) {
        console.log(self.new_path_rule_issued_to);
        // You can only add a rule for roots you dont already have a rule for
        let exists = self.group.group.path_rules.filter(function(value) {
          return value.valid_to === val.sha1;
        });
        self.duplicate_root = exists.length > 0;

        self.root_policies_loading = true;
        UserService.getLocalApi(
          "policies/?issuer_public_key_sha1=" +
            self.new_path_rule_issued_to.public_key.sha1 +
            "&size=10000"
        )
          .then(response => {
            console.log(response.data.results);
            self.root_policies = response.data.results.map(e => ({
              ...e,
              search: e.friendly_name + " " + e.identifier
            }));
            self.root_policies_loading = false;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    new_rule_type() {},
    valid_to_search() {
      // Items have already been loaded
      if (this.roots.length > 0) return;
      if (this.roots_loading) return;

      this.roots_loading = true;
      let self = this;
      UserService.getLocalApi(
        "certificates/?self_signed=true&ca=true&valid=true&size=10000"
      )
        .then(response => {
          self.roots = response.data.results;
          self.roots_loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {
    new_rule_name: function() {
      if (this.new_path_rule_issued_to) {
        return (
          "Valid to " + this.new_path_rule_issued_to.subject_fqdn.friendly_name
        );
      } else {
        return "No name yet";
      }
    }
  }
};
</script>

<style scoped></style>
